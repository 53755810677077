import client from "./requestclient";

const directory = {
  directRecruits: async id => {
    return await client({ URL_PATH: `dashboardcounter/${id}`, method: "GET" });
  },

  leadLog: async id => {
    return await client({ URL_PATH: `fetchleads/${id}`, method: "GET" });
  },

  propertyLog: async id => {
    return await client({
      URL_PATH: `fetchleadswhohaveproperties/${id}`,
      method: "GET"
    });
  },

  landlordProperties: async id => {
    return await client({
      URL_PATH: `fetch-properties-by-landlord-id-in-batces/${id}`,
      method: "GET"
    });
  },

  fetchBanks: async () => {
    return await client({
      URL_PATH: "getbanks",
      method: "GET"
    });
  }
};

export default directory;
